<template>
  <Modal v-model="showConfirm" width="500" title="设置媒体价格" footer-hide :mask-closable="false">
      <Row class="m-b-10">
        <i-col span="4" class="p-t-5">标价</i-col>
        <i-col span="8">
          <InputNumber v-model="inventoryBean.originalPrice" :max="999999" :min="0.01" style="width: 100px;" placeholder="请输入媒体的标准价格"></InputNumber>
        </i-col>
        <i-col span="4" class="p-t-5">初始价格</i-col>
        <i-col span="8">
          <InputNumber v-model="inventoryBean.sellingPrice" :max="999999" :min="0.01" style="width: 100px;" placeholder="请输入媒体的售卖价格"></InputNumber>
        </i-col>
      </Row>
      <Row class="m-b-10">
        <i-col span="4" class="p-t-5">最低限价</i-col>
        <i-col span="8">
          <InputNumber v-model="inventoryBean.statementPrice" :max="999999" :min="0.01" style="width: 100px;" placeholder="请输入媒体的最低限价"></InputNumber>
        </i-col>
        <i-col span="12" class="p-t-5">
          <Checkbox v-model="inventoryBean.dynamic">开启动态定价</Checkbox>
        </i-col>
      </Row>
      <!-- <Row class="m-b-10">
        <i-col span="4" class="p-t-5">服务项</i-col>
        <i-col span="20" class="p-t-5">
          <CheckboxGroup v-model="checkServiceItem">
              <Checkbox v-for="item in serviceItems" :key="item.id" :label="item.id">{{ item.name }}（{{ formatMoney(item.price) }} / {{ item.unitName }}）</Checkbox>
          </CheckboxGroup>
        </i-col>
      </Row> -->

      <template v-if="inventoryBean.electric">
        <Divider>设置电子屏默认售卖规则</Divider>
        <Row class="m-b-10">
          <i-col span="4" class="p-t-5">售卖天数</i-col>
          <i-col span="8">
            <InputNumber v-model="inventoryBean.day" :max="999999" :min="1" style="width: 100px;" placeholder="请输入媒体的售卖天数"></InputNumber>
          </i-col>
        </Row>
        <Row class="m-b-10">
          <i-col span="4" class="p-t-5">投放规格</i-col>
          <i-col span="8">
            <InputNumber v-model="inventoryBean.duration" :max="999999" :min="1" style="width: 100px;" placeholder="请输入媒体的投放规格"></InputNumber>
          </i-col>
          <i-col span="4" class="p-t-5">投放次数</i-col>
          <i-col span="8">
            <InputNumber v-model="inventoryBean.period" :max="999999" :min="1" style="width: 100px;" placeholder="请输入媒体的投放次数"></InputNumber>
          </i-col>
        </Row>
      </template>

      <Row class="m-b-10">
        <i-col span="20" offset="4">
          <p class="remark">tips: 此设置的价格为默认每个档期/规格的售价，若要单独设置，请从列表中编辑单个媒体</p>
        </i-col>
      </Row>

      <Row class="m-b-10">
        <i-col span="4" class="p-t-5">商品主图</i-col>
        <i-col span="20">
          <div class="demo-upload-list" v-for="(item,index) in uploadFiles" :key="index">
            <template v-if="item.status === 'finished'">
                <img :src="item.response.data.link + '?x-oss-process=image/resize,w_100'">
                <div class="demo-upload-list-cover">
                    <Icon type="ios-eye-outline" @click.native="handleView(item.response.data.link)"></Icon>
                    <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
                </div>
            </template>
            <template v-else>
                <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>
          <Upload
            ref="upload"
            :headers="headers"
            :action="uploadUrl"
            :show-upload-list="false"
            :on-success="handleUploadSuccess"
            :format="['jpg','jpeg','png']"
            :max-size="5120"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            :before-upload="handleBeforeUpload"
            multiple
            type="drag"
            style="display: inline-block;width:58px;">
            <div style="width: 58px;height:58px;line-height: 58px;">
                <Icon type="ios-camera" size="20" color="#2d8cf0"></Icon>
            </div>
          </Upload>
        </i-col>
      </Row>
      <Row>
        <i-col span="20" offset="4">
          <p class="remark">tips: 只支持图片上传，图片上传格式为：jpg、jpeg、 png，图片大小不超过5M。</p>
        </i-col>
      </Row>

      <Row>
        <i-col span="20" offset="4">
          <i-button class="m-t-10" :disabled="uploadFiles.length===0" type="primary" @click="handleAdd(false)">保存设置</i-button>
          <i-button class="m-t-10 m-l-10" :disabled="uploadFiles.length===0" type="success" @click="handleAdd(true)">提交并投放</i-button>
        </i-col>
      </Row>

      <Spin fix size="large" v-if="spinShow">
        <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
        <div>数据保存中...</div>
      </Spin>
  </Modal>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { getStorage } from '@/utils/storage'
import { addInventory } from '@/api/product/cloudinventory'

export default {
  data () {
    return {
      showConfirm: false,
      spinShow: false,

      inventoryBean: {
        originalPrice: 0.01,
        sellingPrice: 0.01,
        statementPrice: 0.01, // 结算价
        dynamic: false, // 是否开启动态定价
        type: 1, // 库存类型 1：资源 2：sku
        relateIdList: null,
        day: 1,
        duration: 1,
        electric: false,
        period: 1,
        photos: null,
        isDeliver: false // 是否投放
        // serviceIds: '' // 服务项
      },

      uploadUrl: `${process.env.VUE_APP_API_URL_V2}/ooh-resource/oss/endpoint/put-file`,
      headers: {
        'ooh-auth': getStorage('ooh-token')
      }, // 上传组件设置协议头
      uploadFiles: []

      // serviceItems: [],
      // checkServiceItem: []
    }
  },
  mounted () {
    // const query = {
    //   publisherId: this.$store.getters.token.userInfo.publisherId
    // }
    // getCloudServiceItem(query).then(res => {
    //   this.serviceItems = res
    // })
  },
  methods: {
    showModal (params) {
      this.showConfirm = true
      this.inventoryBean.relateIdList = JSON.stringify(params.selectionResourceIds)
      this.inventoryBean.electric = params.hasElectronic
      this.$refs.upload.fileList = []
      this.uploadFiles = []

      // 保留上一次输入的数据，便于重复操作
      // this.inventoryBean.originalPrice = 0.01
      // this.inventoryBean.sellingPrice = 0.01
      // this.inventoryBean.statementPrice = 0.01
      // this.inventoryBean.day = 1
      // this.inventoryBean.duration = 1
      // this.inventoryBean.period = 1
    },
    handleAdd (isDeliver) {
      this.spinShow = true
      // this.inventoryBean.serviceIds = JSON.stringify(this.checkServiceItem)
      this.inventoryBean.photos = JSON.stringify(this.uploadFiles.map(x => x.response.data.link))
      this.inventoryBean.isDeliver = isDeliver
      const successMsg = isDeliver ? '并成功投放，请注意查看商品投放状态！' : ''

      addInventory(this.inventoryBean).then(res => {
        this.$Notice.success({ desc: '已成功添加资源至媒体库！' + successMsg })
        this.spinShow = false
        this.showConfirm = false
        this.$emit('on-success')
      })
    },
    handleView (fileUrl) {
      window.open(fileUrl)
    },
    handleRemove (file) {
      const fileList = this.$refs.upload.fileList
      this.$refs.upload.fileList.splice(fileList.indexOf(file), 1)
      this.uploadFiles = JSON.parse(JSON.stringify(fileList))
    },
    handleFormatError (file) {
      this.$Notice.warning({
        title: '文件格式错误',
        desc: '上传文件： ' + file.name + ' 格式异常, 请选择png或者jpg格式的图片。'
      })
    },
    handleMaxSize (file) {
      this.$Notice.warning({
        title: '上传文件大小异常',
        desc: '文件：  ' + file.name + ' 太大, 上传文件不能超过5M'
      })
    },
    handleBeforeUpload () {
      const check = this.uploadFiles.length < 5
      if (!check) {
        this.$Notice.warning({
          title: '不能上传超过5个主图，请删除其他图片后，重新上传！'
        })
      }
      return check
    },
    handleUploadSuccess (response, file) {
      if (response && response.success) {
        this.uploadFiles.push(file)
      }
    },
    formatMoney (number) {
      return toMoney(number)
    }
  }
}
</script>

<style scoped>
    .demo-upload-list{
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0,0,0,.2);
        margin-right: 4px;
    }
    .demo-upload-list img{
        width: 100%;
        height: 100%;
    }
    .demo-upload-list-cover{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.6);
    }
    .demo-upload-list:hover .demo-upload-list-cover{
        display: block;
    }
    .demo-upload-list-cover i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }
</style>
